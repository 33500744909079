import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, {
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import FiveColumnDark from "components/footers/FiveColumnDark";

const StyledHeader = styled(Header)`
  ${tw`py-4 px-4 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside mt-4`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Jewels of Ramgarhia" }) => {
  return (
    <>
      <StyledHeader />
      <Container>
        <AnimationRevealPage>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <h2>GREAT SAINTS :</h2>

              <ul>
                <li>
                  <p>
                    <strong>Lord Vishwakarma Ji</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Bhai Lalo (Ghataurey)</strong> Follower of Guru
                    Nanak Dev Ji
                  </p>
                </li>
                <li>
                  <strong>Braham Giani Sant Baba Nand Singh ji Maharaj</strong>{" "}
                  Nanaksar Kaleran, Ludhiana
                </li>
                <li>
                  <strong>Satguru Ram Singh ji</strong> Namdhari{" "}
                </li>
                <li>
                  <strong>Satguru Jagjeet Singh Ji [Matharoo]</strong> Naamdhari{" "}
                </li>{" "}
                <li>
                  <strong>Sant Sucha Singh Ji</strong> Jawadikalan, Ludhiana
                </li>
                <li>
                  <strong>Nattan wale Sant</strong> Ludhiana Seehat
                </li>
                <li>
                  <p>
                    <strong>Sant Harjeet Singh ji Bhamrah</strong> Bhai Lalo
                    Ashram, Ludhiana
                  </p>
                </li>
                <li>
                  <strong>Singh Sahib Bhai Ranjeet Singh</strong> Jathedar of
                  Akal Takth
                </li>
                <li>
                  <p>
                    <strong>Jodh Singh</strong> son and successor of Jassa Singh
                    Ramgarhia
                  </p>
                </li>
              </ul>
              <h2>GREAT KIRTANEY :</h2>

              <ul>
                <li>
                  <p>
                    <strong>Bhai Jawala Singh Ji</strong> Hazoori Raagi Darbar
                    Sahib ASR
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Bhai Avtar Singh ji, Bhai Gurcharan Singh ji
                    </strong>{" "}
                    Classical Raagi - Delhi
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Dr. Bhai Gurinder Singh ji</strong> Classical Raagi
                    - Batala
                  </p>
                </li>

                <li>
                  <p>
                    <strong>Ustad Bhai Baldeep Singh ji</strong> Classical
                    Drupad Kirtankaar - Delhi{" "}
                  </p>
                </li>

                <li>
                  <p>
                    <strong>Bhai Kultar Singh Ji</strong> Hajuri Raagi Gurdwara
                    Rakab Ganj - Delhi
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Beant Singh Chana</strong> -A Great Listener of
                    Gurbani and classical Music - Toronto
                  </p>
                </li>
              </ul>
              <h2>POLITICIANS :</h2>

              <ul>
                <li>
                  <p>
                    <strong>Giani Zail Singh</strong> Chief Minister of Punjab
                    and First Sikh President of lndia{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Hira Singh Gabadia</strong> Ex Jail Minister Punjab
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Gurcharan Singh Channi</strong> Deputy Commisioner,
                    Ldh{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Mali Singh</strong>
                    Sikh Warrior and Misl General
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Tara Singh</strong> Sikh Warrior, Misl General &
                    younger brother of Jassa Singh Ramgarhia{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Bhagwan Singh</strong>
                    Scholar & Warrior during Sikh Misl Period, father of Jassa
                    Singh
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Mangal Singh</strong> Soldier and later manager of
                    the Golden Temple of Amritsar{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Dalip Singh Saund</strong> First Sikh United States
                    Congresssman
                  </p>
                </li>
              </ul>

              <h2>WRITERS AND POETS :</h2>

              <ul>
                <li>
                  <p>
                    <strong> Dr. Harbhajan Singh</strong> Winner of Kabir,
                    Saraswati Sanmaans, Prof. Emeritus, University of Delhi{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong>Gurdial Singh Jaito</strong>
                    Eminent novelist, winner of Bhartiya Gyanpeeth Award{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong>Ajmer Rode</strong> Canada based eminent poet,
                    dramaturg and translator
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong>Surjit Paatar</strong> Amongst the best known
                    Punjabi poets today
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong>Kehar Singh Matharoo</strong> Author of Kirti
                    Surmein - Toronto, Canada
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Madan Gopal Singh</strong> Lyricist and Composer
                    for “Khamosh Pani”{" "}
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Dr. Sohinder Bir Singh</strong> One of the Best
                    Punjabi Poets, Winner of Bawa Balwant Award
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Gurdev Singh Bhogal</strong> Religious & Spiritual
                    poet
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Tara Singh Anjan (Saggu)</strong>
                    Punjabi Writer, Educationists
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong> Jagdish Singh Wariam (Renoo)</strong>
                    Journalist
                  </p>
                </li>{" "}
                <li>
                  <p>
                    <strong>Surinder Kaur</strong> Punjabi Writer
                  </p>
                </li>{" "}
              </ul>

              <h2>BUSINESS PEOPLE :</h2>

              <ul>
                <li>
                  <p>
                    {" "}
                    <strong>Standard Tractors, Barnala</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>
                      Ratan Singh/Basant Singh, Basant Power Presses
                    </strong>{" "}
                    Ludhiana
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Preet Tractors</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>GNA Group of Industries, Goraya</strong>{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Charan Singh Hunjan</strong> Famous builder of all
                    Ramgarhia Gurdwaras also an foreman
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Narminder Singh Hunjan</strong> Famous Senior Ex
                    Eng. from Punjab State Electricity Board, lndia
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>
                      S. Bakshish Singh Dhanjal, Modern Group of Industries
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>
                      S. Harbans Singh Khokhar, Kawal Furniture Pvt. Ltd.,
                      Interior Decorator
                    </strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Balwant Singh Hunjan</strong> Famous Orthopaedic
                    Surgeon in Punjab, Ludhiana (Hunjan Hospital
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Harpal Matharu</strong> Owner Global Grange Group -
                    Worth $400 million
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Mohinder Singh Bhogal</strong> Founder Bhogal Cycles
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Baba Gurmukh Singh</strong> Founder - G S. Auto
                    International Limited
                  </p>
                </li>
              </ul>

              <h2> ATHLETES:</h2>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong>Gadowar Singh Sahota</strong> Professional Wrestler
                    known as The Great Gama (WWF
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Bakhshish Singh </strong> Winner of Asian cycling
                    championship
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Joginder Singh </strong> Winner of the East African
                    Safari Rally and first man to score the elusive safari
                    hat-trick
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Joginder Singh Gindi</strong> Member, lndian Hockey
                    Team in the 1960s
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Virender Singh</strong> Famous right-half of the
                    lndian hockey team that won the 1975 World Cup
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Harbhajan Singh [Bhajji] Plaha</strong> lndian
                    Cricketer{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Sandeep Singh</strong> Former Indian Hockey Captain{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Sarandeep Singh</strong> lndian Cricketer
                    (specialist offspinner){" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Monty Panesar</strong> England Cricketer{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Bahadur Singh Saggu</strong> lndian Strongman and
                    Olympic Shotputter and Asian Games Gold Medalist{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Jatinder Singh Mudarh</strong> lndian Strongman and
                    given the title “The Strongest Man in Punjab”{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Mandeep Singh Bhogal - AKA Iron Singh</strong>
                    American Body Builder{" "}
                  </p>
                </li>
              </ul>
              <h2> TELEVISION AND MEDIA PEOPLE:</h2>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong>Jagjeet Singh</strong> World famous Ghazal singer{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Amrita Kaur Hunjan</strong>{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Ajay Devgan</strong> Bollywood superstar{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Jagjeet Singh</strong> Famous Gazal singer
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Ameet Channa</strong> British Film Actor & BBC Asian
                    Network presenter{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Kulvinder Ghir</strong> ActorlComedian Goodness
                    Gracious Me
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>lnderjeet Nikku</strong> Punjabi Singer
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Satnam Bhogal</strong> British Actor - Holby City,
                    Baby Father, Steal River Blues
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Suzanne Virdee</strong> News Presenter
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Vicky Bhogal</strong> UK TV Chef & Author
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Harbhajan Jabbal </strong> TV/Theater artist
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Kanwaljeet Singh</strong> Punjabi film star
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Kanwaljit Singh Virdi </strong> Journalist at
                    Chandigarh (Buerau Chief North with Standard World TV)
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Dipps Bhamrah </strong> BBC Asian Network presenter
                    and DJ
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Ravinder Bhogal </strong> UK TV Chief and presenter
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Puneet Sira</strong> Director, Producer, Writer of
                    films such as Proud to be an lndian
                  </p>
                </li>
              </ul>

              <h2>MUSICIANS & SINGERS:</h2>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong> Bally Sagoo</strong> World Famous Music Producer
                    ahd first Sikh Artist to cross over to UK Charts{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong> Surinder Shinda (Hunjan)</strong> Punjabi Folk
                    Singer and Icon{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Talvin Singh</strong> Musician and world renowned
                    percussionist{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Sukhshinder Shinda (Bhullar Ramgharia</strong>{" "}
                    famous Punjabi singer and musician{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Heps Jandu & Ricky Hunjan</strong> Best known
                    Punjabi by nature (PBN) DJ
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Silinder Pardesi</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Herbie Sahara</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Kam Bhamra</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>KS Bhamra (Apna Sangeet)</strong>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Pal D.</strong>
                  </p>
                </li>
              </ul>
              <h2>EMINENT PAINTERS:</h2>
              <ul>
                <li>
                  <p>
                    {" "}
                    <strong>S G Thakur Singh</strong> Artist, Teacher and
                    Patriot
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Sobha Singh</strong> Most Respected and Famous
                    Artist and Religious Painter
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Prem Singh</strong> Eminent Artist & Teacher,
                    Retired as the Principal of the Art College, Chandigarh
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <strong>Gobinder Singh Sohal</strong> Famous Artist and
                    Religious Painter from Patiala studied from Norway
                  </p>
                </li>
              </ul>
            </Text>
          </ContentWithPaddingXl>
        </AnimationRevealPage>
      </Container>
      <FiveColumnDark />
    </>
  );
};
