import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, {
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import FiveColumnDark from "components/footers/FiveColumnDark";

const StyledHeader = styled(Header)`
  ${tw`py-4 px-4 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside mt-4`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Ramgarhia Misl" }) => {
  return (
    <>
      <StyledHeader />

      <Container>
        <AnimationRevealPage>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>
                RAMGARHIA MlSL took its name from Ram Rauni, an enclosure of
                unbaked bricks raised in Amritsar during the time of Jassa Singh
                for the protection of Sikhs in the troubled days of the
                eighteenth century. The fortress was later reinforced by Sikhs
                and made into a fort called Ramgarh. Jassa Singh became famous
                in Sikh history as Jassa Singh Ramgarhia. He gained reputation
                as a soldier of daring and skill. He along with his brothers Jai
                Singh, Kushal Singh and Mali Singh took up service under Adina
                Beg, faujdar of the Jalandhar Doab, which he quit when the Sikhs
                taunted him with betrayal of the Panth. To begin with, Jassa
                Singh joined hands with Jai Singh of the Kanhaiya misl and
                within a short time they seized large slices of territory in
                four out of the five Doabs. Among their acquisitions was the
                fertile tract called Riarki to the north of Amritsar embracing
                the district of Gurdaspur. Within a decade Jassa Singh became
                one of the leading figures of the Dal Khalsa, In 1770, he led
                plundering expeditions into the hills. The local rajas sought
                safety in submission and Jassa Singh collected a tribute of
                2,00,000 rupees from the Kangra hill states. Jassa Singh
                Ramgarhia, along with other Sikh sardars, fought many pitched
                battles against Ahmad Shah Durrani, the Afghan invader
              </p>
              <br />
              <br />
              <p>
                As the Afghan threat receded, Sikh sardars began fighting among
                themselves. The Ramgarhia Kanhaiya cleavage over their adjoining
                territories in the district of Gurdaspur and Hoshiarpur widened.
                In the battle of Dinanagar in 1770 S. Jassa Singh Ramgarhia
                joined the Bhangi sardars against the forces of the Kanhaiyas
                and the Sukkarchakkias. Soon a rift appeared between Jassa Singh
                Ramgarhia and Jassa Singh Ahluvalia as the latter wrested the
                town of Zahura, which fell within Ramgarhia territory, and
                conferred it upon Baghel Singh Karorsinghia. Jai Singh Kanhaiya
                sought the help of Jassa Singh Ahluvalia and the Ramgarhia
                Sardar had to flee the Punjab.
              </p>
              <br />
              <br />
              <p>
                Driven out of the Punjab, Jassa Singh became a soldier of
                fortune. He took possession of Hissar and raised a large body of
                irregular horse, his depredations extending to the gates of
                Delhi and its suburbs, and into the Gangetic Doab. Once he
                penetrated into Delhi itself, and carried off four guns from the
                Mughal arsenal. The Nawab of Meerut agreed to pay him 10,000
                rupees an year on his agreeing to leave his district unmolested.
                Soon a body of 30,000 horse and foot under him and Karam Singh
                Shahid crossed into Saharanpur district, ravaging it at will. On
                the death of Jassa Singh Ahluvalia in 1783, Jassa Singh
                Ramgarhia returned to the Punjab and recovered his lost
                possessions. He allied himself with the Sukkarchakkias, and
                their combined forces broke the power of the Kanhaiyas.
              </p>
              <br />
              <br />
              <p>
                At the height of its power, Ramgarhia misl’s territories in the
                Bari Doab included Batala, Kalanaur, Dinanagar, Sri
                Hargobindpur, Shahpur Kandi, Gurdaspur, Qadian, Ghuman, Matteval
                and in the Jalandhar Doab, Urmur Tanda, Sanh, Miani, Garhdivala
                and Zahura. In the hills Kangra, Nurpur, Manndi and Chamba paid
                tribute to Jassa Singh.
              </p>
              <br />
              <br />
              <p>
                Jassa Singh died in April 1803 at the ripe age of 80, leaving
                two sons, Jodh Singh and Vir Singh, the former of whom succeeded
                him. Jodh Singh was a deeply religious person. He built the
                Ramgarhia Bunga on the premises of the Harmandir at Amritsar and
                supplied blocks of perforated marble that served as parapets on
                both sides of the causeway leading to the sanctuary. Jodh
                Singh’s possessions were encroached upon by his more active
                cousin, Divan Singh, son of Tara Singh. In 1808, Ranjit Singh
                took possession of the territories of the Ramgarhia misl. The
                same year he captured the fortress of Ramgarh, destroying all
                the Ramgarhia citadels. Adequate pensions were provided for
                Divan Singh and Jodh Singh, the leaders of the once powerful
                Ramgarhia misl which had like many others collapsed under
                pressure of the new rising power in the Punjab.
              </p>
            </Text>
          </ContentWithPaddingXl>
        </AnimationRevealPage>{" "}
      </Container>

      <FiveColumnDark />
    </>
  );
};
