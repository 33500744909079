import { useEffect } from "react";
import ProfileThreeColGrid from "components/cards/ProfileThreeColGrid";
import ProfileThreeColGridSmall from "components/cards/ProfileThreeColGridSmall";
import GetStartedLight from "components/cta/GetStartedLight";
import ThreeColCenteredStatsPrimaryBackground from "components/features/ThreeColCenteredStatsPrimaryBackground";
import FiveColumnDark from "components/footers/FiveColumnDark";
import TwoColumnWithFeaturesAndTestimonial from "components/hero/TwoColumnWithFeaturesAndTestimonial";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

const peopleSabha = [
  {
    imageSrc: "./assets/members/S. Mahindra Singh Bhambra.jpeg",
    position: "President",
    name: "S. Mahindra Singh Bhambra",
  },
  {
    imageSrc: "./assets/members/S. Surmail Singh Flora.jpeg",
    position: "General Secretary",
    name: "S. Surmail Singh Flora",
  },
  {
    imageSrc: "./assets/members/S. Surjit Singh Sembhi.jpeg",
    position: "Assistant Secretary",
    name: "S. Surjit Singh Sembhi",
  },
  {
    imageSrc: "./assets/members/S. Indrajit Singh Jaswal.jpeg",
    position: "Cashier",
    name: "S. Indrajit Singh Jaswal",
  },
  {
    imageSrc: "./assets/members/S. Rajender Singh Surajwanshi.jpeg",
    position: "Auditor",
    name: "S. Rajender Singh Surajwanshi",
  },
  {
    imageSrc: "./assets/members/S. Manjit Singh Sall.jpeg",
    position: "Propoganda Secretary",
    name: "S. Manjit Singh Sall",
  },
];

const peopleTrust = [
  {
    imageSrc: "./assets/members/noImage.png",
    position: "Chairman",
    name: "S. Ratan Singh Deo",
  },
  {
    imageSrc: "./assets/members/noImage.png",
    position: "General Secretary",
    name: "S. Nirmal Singh Matharoo",
  },
  {
    imageSrc: "./assets/members/noImage.png",
    position: "Treasurer",
    name: "S. Jaswinder Singh Kalsi",
  },
];

const peopleCoop = [
  {
    imageSrc: "./assets/members/S. Harbhajan Singh Thethi.jpeg",
    position: "President",
    name: "S. Harbhajan Singh Thethi",
  },

  {
    imageSrc: "./assets/members/noImage.png",
    position: "Vice President",
    name: "S. Jasbir Singh Deosi",
  },

  {
    imageSrc: "./assets/members/S. Aminder Singh Chagger.jpg",
    position: "Secretary",
    name: "S. Aminder Singh Chagger",
  },
];

const peopleYouth = [
  {
    imageSrc: "./assets/members/noImage.png",
    position: "President",
    name: "S.Iqbal Singh Lotey",
  },

  {
    imageSrc: "./assets/members/noImage.png",
    position: "Vice President",
    name: "S.Satpal Singh Khokhar",
  },

  {
    imageSrc: "./assets/members/noImage.png",
    position: "Secretary",
    name: "S.Harmeek Singh Matharoo",
  },
];

const people2 = [
  {
    imageSrc: "./assets/members/S. Jagjeet Singh Dhanjal.jpg",
    position: "Jathedar",
    name: "S. Jagjeet Singh Dhanjal",
  },
  {
    imageSrc: "./assets/members/S. Ranjit Singh Reehal.jpeg",
    position: "Jathedar",
    name: "S. Ranjit Singh Reehal",
  },
  {
    imageSrc: "./assets/members/S. Nirmal Singh Bansal.jpeg",
    position: "Jathedar",
    name: "S. Nirmal Singh Bansal",
  },
  {
    imageSrc: "./assets/members/S. Balbir Singh Chaney.jpeg",
    position: "Jathedar",
    name: "S. Balbir Singh Chaney",
  },
  {
    imageSrc: "./assets/members/S. Davinder Singh Dhanjal.jpeg",
    position: "Jathedar",
    name: "S. Davinder Singh Dhanjal",
  },
  {
    imageSrc: "./assets/members/S. Gurbachan Singh Khokhar.jpeg",
    position: "Jathedar",
    name: "S. Gurbachan Singh Khokhar",
  },
  {
    imageSrc: "./assets/members/S. Jasbir Singh Matharu.jpeg",
    position: "Jathedar",
    name: "S. Jasbir Singh Matharu",
  },
  {
    imageSrc: "./assets/members/S. Sarwan Singh Sall.jpeg",
    position: "Jathedar",
    name: "S. Sarwan Singh Sall",
  },
  {
    imageSrc: "./assets/members/S. Harnek Singh Kalsi.jpeg",
    position: "Jathedar",
    name: "S. Harnek Singh Kalsi",
  },
  {
    imageSrc: "./assets/members/S. Kirpal Singh Sagoo.jpeg",
    position: "Jathedar",
    name: "S. Kirpal Singh Sagoo",
  },
  {
    imageSrc: "./assets/members/S. Surendra Pal Singh Nagi.jpeg",
    position: "Accountant",
    name: "S. Surendra Pal Singh Nagi",
  },
  {
    imageSrc: "./assets/members/Shri Sadanand Saroj.jpeg",
    position: "Caretaker",
    name: "Shri Sadanand Saroj",
  },
];

function Home() {
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <TwoColumnWithFeaturesAndTestimonial
        primaryButtonText="View Jewels"
        primaryButtonUrl="/jewels"
      />
      <AnimationRevealPage>
        <ThreeColCenteredStatsPrimaryBackground />
      </AnimationRevealPage>

      <AnimationRevealPage>
        <ProfileThreeColGrid
          heading="RAMGARHIA SEWAK SABHA KOLKATA"
          subheading="Managing Committee of"
          cards={peopleSabha}
        />
      </AnimationRevealPage>

      <AnimationRevealPage>
        <ProfileThreeColGrid
          heading="RAMGARHIA TRUST"
          subheading="Managing Committee of"
          cards={peopleTrust}
        />
      </AnimationRevealPage>

      <AnimationRevealPage>
        <ProfileThreeColGrid
          heading="RAMGARHIA CO-OP. CREDIT SOCIETY LTD."
          subheading="Managing Committee of"
          cards={peopleCoop}
        />
      </AnimationRevealPage>

      <AnimationRevealPage>
        <ProfileThreeColGrid
          heading="RAMGARHIA YOUTH WING"
          subheading="Managing Committee of"
          cards={peopleYouth}
        />
      </AnimationRevealPage>
      {/* <AnimationRevealPage>
        <ProfileThreeColGridSmall cards={peopleSabha2} />
      </AnimationRevealPage> */}

      <AnimationRevealPage>
        <GetStartedLight
          primaryLinkText="View Misl"
          primaryLinkUrl="/misl"
          secondaryLinkText="View Jewels"
          secondaryLinkUrl="/jewels"
        />
      </AnimationRevealPage>

      <FiveColumnDark />
    </div>
  );
}

export default Home;
